<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="mb-3">
          <Toolbar>
            <template v-slot:left>
              <Button icon="pi pi-arrow-left" class="p-button-rounded p-button-text" @click="goToMonth('prev')"/>&nbsp;
              <Calendar id="dateRange" :showIcon="true" v-model="date_range" view="month" dateFormat="MM yy" @hide="getTransactions()"></Calendar>&nbsp;
              <Button icon="pi pi-arrow-right" class="p-button-rounded p-button-text" @click="goToMonth('next')"/>
            </template>
            <template v-slot:right>
              <Dropdown v-model="selectedAccount" :options="accountList" optionLabel="type" optionValue="id" placeholder="Select an Account" @change="getTransactions()" />
              <Button icon="pi pi-plus" label="Add Transaction" class="ml-2 p-button-success bg-green-100 text-green-500" @click="newTransaction" />
            </template>
          </Toolbar>
        </div>
        <div class="transactions-subtable">
          <DataTable :value="transactions.transactions" responsiveLayout="scroll" class="p-datatable-sm">
            <Column field="description" header="Description">
              <template #body="slotProps">
                <span :class="{ 'text-gray-400': !slotProps.data.posted }">{{ slotProps.data.description }}</span>
              </template>
            </Column>
            <Column field="date" header="Date">
              <template #body="slotProps">
                <span :class="{ 'text-gray-400': !slotProps.data.posted }">{{ formatDate(slotProps.data.date) }}</span>
              </template>
            </Column>
            <Column field="cost" header="Cost">
              <template #body="slotProps">
                <span :class="{ 'text-pink-500': slotProps.data.category.type == 'E', 'text-green-500': slotProps.data.category.type == 'I' }">{{formatCurrency(slotProps.data.cost)}}</span>
              </template>
            </Column>
            <Column field="total" header="Total">
              <template #body="slotProps">
                {{ formatCurrency(calculateRowTotal(slotProps.data)) }}
              </template>
            </Column>
            <Column field="order" header="Order"></Column>
            <!-- <Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column> -->
            <Column>
              <template #body="slotProps">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-text mr-2 p-button-secondary p-button-sm" @click="editTransaction(slotProps.data.id)" />
                <Button icon="pi pi-trash" class="p-button-rounded p-button-text mr-2 p-button-danger p-button-sm" @click="confirmDeleteTransaction(slotProps.data.id)" />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>

  <transaction-modal v-if="transactionDialog" v-model:visible="transactionDialog" @visible="updateTransactionDialog" @refresh="getTransactions" :transaction_id="transaction_id"></transaction-modal>
</template>

<script>
  import AccountService from '../service/AccountService'
  import TransactionModal from '../components/TransactionModal.vue'

  export default {
    components: {
      TransactionModal
    },
    data() {
      return {
        date_range: "",
        transactions: [],
        accountList: [],
        selectedAccount: '',
        transaction_id: null,
        transactionDialog: false,
      }
    },
    created() {
      this.accountService = new AccountService()
      this.runningTotal = 0
      this.startingBalance = this.getStartingBalance()
      this.selectedAccount = 1
    },
    mounted() {
      const date = new Date(), y = date.getFullYear(), m = date.getMonth()
      const firstDay = new Date(y, m, 1)
      this.date_range = firstDay
      this.getTransactions()
      this.getAccounts()
    },
    methods: {
      goToMonth(value) {
        if (value == 'prev') {
          this.date_range = new Date(this.date_range.getFullYear(), this.date_range.getMonth()-1, 1)
        } else if (value == 'next') {
          this.date_range = new Date(this.date_range.getFullYear(), this.date_range.getMonth()+1, 1)
        }
        this.runningTotal =
        this.startingBalance = this.getStartingBalance()
        this.getTransactions()
      },
      getStartingBalance() {
        return 10000
      },
      getAccounts() {
        this.accountService.getAccounts().then(data => this.accountList = data)
      },
      getTransactions() {
        const y = this.date_range.getFullYear(), m = this.date_range.getMonth()
        const firstDay = new Date(y, m, 1)
        const lastDay = new Date(y, m + 1, 0)
        this.accountService.getAccountTransactions(this.selectedAccount, firstDay.toLocaleDateString('en-ca'), lastDay.toLocaleDateString('en-ca')).then(data => {
          this.transactions = data
          console.log(data)
        })
      },
      formatCurrency(value) {
        return value.toLocaleString('en-CA', {style: 'currency', currency: 'CAD'})
      },
      formatDate(value) {
        const newDate = new Date(value)
        return newDate.toLocaleDateString('en-ca', {timeZone: 'UTC'})
      },
      calculateRowTotal(row) {
        let cost = 0
        let runningTotal = this.runningTotal
        if (runningTotal == 0)
          runningTotal = this.startingBalance

        if (row.category.type == 'E') {
          cost = row.cost * -1
        } else {
          cost = row.cost
        }
        runningTotal += cost
        this.runningTotal = runningTotal
        return runningTotal
      },
      newTransaction() {
        this.transaction_id = null
        this.transactionDialog = true
      },
      updateTransactionDialog(value) {
        this.transactionDialog = value
      },
      editTransaction(transaction_id) {
        this.transaction_id = transaction_id
        this.transactionDialog = true
      }
    }
  }
</script>
